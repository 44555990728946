import Swipe from 'swipejs';
import './vendor/lightbox.js';

( function() {

	( function() {
		// Nav toggle
		const nav_toggle = document.getElementById( 'js-nav-open' );
		nav_toggle.addEventListener( 'click', ( () => {
			document.body.classList.toggle( 'body-nav-open' );
			nav_toggle.classList.toggle( 'nav__toggle--open' );
		} ) );
	} )();

	( function() {
		// Swipe
		window.mySwipe = new Swipe( document.getElementById( 'slider' ), {
			speed: 300,
			auto: 3000,
			continuous: true,
			draggable: true
		} );
	} )();

	( function() {
		// Height match columns
		function resetElementHeights( elements ) {
			for ( let i = 0; i < elements.length; i += 1 ) {
				elements[i].style.height = 'auto';
			}
		}

		function getTallestElement( elements ) {
			let tallest = elements[0];
			for ( let i = 1; i < elements.length; i += 1 ) {
				if ( elements[i].offsetHeight > tallest.offsetHeight ) {
					tallest = elements[i];
				}
			}
			return tallest;
		}

		const elements = document.querySelectorAll( '.heightmatch' );
		const bins = {};

		function heightmatchBin( bin ) {
			resetElementHeights( bin.elements );
			const shortest = getTallestElement( bin.elements );
			for ( let i = 0; i < bin.elements.length; i += 1 ) {
				bin.elements[i].style.height = shortest.offsetHeight + 'px';
			}
		}

		function getBinsSortedByBreakpointDesc() {
			// JS objects have no inherent order, so we must work around it.
			const sorted_bins = [];
			for ( const bin_id in bins ) {
				if ( !Object.prototype.hasOwnProperty.call( bins, bin_id ) ) {
					continue;
				}
				sorted_bins.push( bins[bin_id] );
			}
			sorted_bins.sort( ( ( a, b ) => {
				return b.breakpoint - a.breakpoint;
			} ) );
			return sorted_bins;
		}

		function heightmatchAllBins() {
			const window_width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			const sorted_bins = getBinsSortedByBreakpointDesc();
			for ( let i = 0; i < sorted_bins.length; i += 1 ) {
				if ( window_width > sorted_bins[i].breakpoint ) {
					continue;
				}
				heightmatchBin( sorted_bins[i] );
			}
		}

		for ( let i = 0; i < elements.length; i += 1 ) {
			const bin_ids = elements[i].getAttribute( 'data-heightmatch-bins' ).split( '|' );
			for ( let j = 0; j < bin_ids.length; j += 1 ) {
				const bin_def = bin_ids[j].split( ':' );
				let bin_id;
				let breakpoint;
				if ( bin_def.length === 1 ) {
					breakpoint = Infinity; // Default breakpoint
					bin_id = bin_def[0];
				} else {
					breakpoint = parseInt( bin_def[0], 10 );
					bin_id = bin_def[1];
				}
				if ( !Object.prototype.hasOwnProperty.call( bins, bin_id ) ) {
					bins[bin_id] = {
						breakpoint: breakpoint,
						elements: []
					};
				}
				bins[bin_id].elements.push( elements[i] );
			}
		}

		window.addEventListener( 'load', ( () => {
			heightmatchAllBins();
		} ) );

		window.addEventListener( 'resize', ( () => {
			heightmatchAllBins();
		} ) );

	} )();

} )();
