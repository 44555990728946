( function(){
	'use strict';
	var links = document.querySelectorAll( '.lightbox' );

	if ( !links ){
		return;
	}

	var current_link_id = 0;
	var lightbox = document.createElement( 'div' );
	var lightbox_inner = document.createElement( 'div' );
	var lightbox_close = document.createElement( 'button' );
	var lightbox_prev = document.createElement( 'button' );
	var lightbox_next = document.createElement( 'button' );
	var lightbox_caption_container = document.createElement( 'div' );
	var p = document.createElement( 'div' );

	lightbox.className = 'lightbox__frame disabled';
	lightbox_inner.className = 'lightbox__inner';
	lightbox_close.className = 'lightbox__button lightbox__button--close';
	lightbox_prev.className = 'lightbox__button lightbox__button--prev';
	lightbox_next.className = 'lightbox__button lightbox__button--next';
	lightbox_close.setAttribute( 'aria-label', 'Close lightbox' );
	lightbox_prev.setAttribute( 'aria-label', 'Next slide' );
	lightbox_next.setAttribute( 'aria-label', 'Previous slide' );
	lightbox_caption_container.className = 'lightbox__caption';
	lightbox.appendChild( lightbox_inner );
	lightbox.appendChild( lightbox_close );
	lightbox.appendChild( lightbox_prev );
	lightbox.appendChild( lightbox_next );
	lightbox.insertBefore( lightbox_caption_container, lightbox_inner );
	lightbox_caption_container.appendChild( p );
	document.body.insertBefore( lightbox, document.body.firstChild );

	lightbox.addEventListener( 'click', function( event ){
		if( event.target === lightbox ){
			hideLightBox();
		}
	} );
	lightbox_close.addEventListener( 'click', function( event ){
		hideLightBox();
	} );
	lightbox_prev.addEventListener( 'click', function( event ){
		goToSlideOffset( -1 );
	} );
	lightbox_next.addEventListener( 'click', function( event ){
		goToSlideOffset( 1 );
	} );
	document.addEventListener( 'keyup', function( event ){
		if( event.keyCode === 27 ){ // Escape
			hideLightBox();
		}
		else if( event.keyCode === 37 ){ // Left
			goToSlideOffset( -1 );
		}
		else if( event.keyCode === 39 ){ // Right
			goToSlideOffset( 1 );
		}
	} );
	window.addEventListener( 'resize', function( event ){
		updateLightBoxContent();
	} );

	for( var i = 0; i < links.length; i += 1 ){
		links[i].setAttribute( 'data-slide-id', i.toString() );
		links[i].addEventListener( 'click', handleClick( links[i] ) );
	}

	function slideFromContent( type, content, loaded_callback ){
		var element;
		switch( type ){

			case 'image':
				element = document.createElement( 'img' );
				element.addEventListener( 'load', function(){
					loaded_callback( element );
				} );
				window.setTimeout( function(){
					element.src = content;
				}, 1 );
				break;

			case 'iframe':
				element = document.createElement( 'div' );
				var iframe = document.createElement( 'iframe' );
				element.className = 'flex-container';
				element.appendChild( iframe );
				iframe.frameborder = 0;
				iframe.allowfullscreen = true;
				iframe.src = content;
				loaded_callback( element );
				break;

		}
		return element;
	}

	function handleLightBoxLink( link ){
		current_link_id = parseInt( link.getAttribute( 'data-slide-id' ), 10 );
		updateLightBoxContent();
		showLightBox();
	}

	function clearLightBox(){
		lightbox_inner.innerHTML = '';
	}

	function updateLightBoxContent(){
		lightbox.classList.add( 'loading' );
		slideFromContent( links[current_link_id].getAttribute( 'data-lightbox-type' ), links[current_link_id].getAttribute( 'href' ), function( content ){
			clearLightBox();
			lightbox_inner.appendChild( content );
			centreLightBoxContent( content );
			lightbox.classList.remove( 'loading' );
		} );

		if( links[current_link_id].getAttribute( 'data-lightbox-caption' ) ){
			p.innerHTML = links[current_link_id].getAttribute( 'data-lightbox-caption' );
			return;
		}
		p.innerHTML = '';
	}

	function centreLightBoxContent( content ){
		var window_height = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
		var max_slide_height = window_height - 60;
		lightbox_inner.style.maxWidth = '';
		if( content.offsetHeight > max_slide_height ){
			var aspect_ratio = content.offsetWidth / content.offsetHeight;
			lightbox_inner.style.maxWidth = ( aspect_ratio * max_slide_height ).toString() + 'px';
		}
		lightbox_inner.style.marginTop = Math.round( ( window_height - content.offsetHeight ) / 2 ).toString() + 'px';
	}

	function showLightBox(){
		lightbox.classList.remove( 'disabled' );
	}

	function hideLightBox(){
		lightbox.classList.add( 'disabled' );
		clearLightBox();
	}

	function goToSlideOffset( offset ){
		var new_slide_index = ( links.length + ( current_link_id + offset ) ) % links.length;
		current_link_id = new_slide_index;
		updateLightBoxContent();
	}

	function handleClick( link ){
		return function( event ){
			if( event.preventDefault ){
				event.preventDefault();
			}
			else {
				event.returnValue = false;
			}
			handleLightBoxLink( link );
		};
	}

} )();
